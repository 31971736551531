(function () {
    const mapR = document.getElementById('regional-map');
    if (mapR) {
        const areasR = mapR.querySelectorAll('.map-area');
        const countyCards = document.querySelectorAll('.county-card');
        const labels = document.querySelectorAll('.map-with-titles__label');
        areasR.forEach(area => {
            area.addEventListener('click', function () {
                areasR.forEach(item => item.classList.remove('active'));
                const id = this.getAttribute('id');
                this.classList.add('active');
                showLabel(id)
                countyCards.forEach(card => {
                    if (card.getAttribute('id') === id) {
                        card.classList.add('active')
                    } else {
                        card.classList.remove('active');
                    }

                })
            })
            area.addEventListener('mouseenter', function () {
                const id = this.getAttribute('id');
                const label = document.querySelector(`.map-with-titles__label--${id}`);
                label.classList.add('hover')
            })
            area.addEventListener('mouseleave', function () {
                const id = this.getAttribute('id');
                const label = document.querySelector(`.map-with-titles__label--${id}`);
                label.classList.remove('hover')
            })
        })

        function showLabel(id) {
            if (labels) {
                labels.forEach(label => {
                    if (label.classList.contains(`map-with-titles__label--${id}`)) {
                        label.classList.add('active')
                    } else {
                        label.classList.remove('active');
                    }
                })
            }
        }
    }

})()