(function () {
    const teamPopup = document.querySelector('.team-popup')

    if (teamPopup) {
        const popupImage = teamPopup.querySelector('.team-popup__image img');
        const popupTitle = teamPopup.querySelector('.team-popup__title');
        const popupSubtitle = teamPopup.querySelector('.team-popup__subtitle');
        const popupDesc = teamPopup.querySelector('.team-popup__text');
        const popupClose = teamPopup.querySelector('.team-popup__close');

        popupClose.addEventListener('click', function () {
            teamPopup.classList.remove('open');
            bodyScrollLock.clearAllBodyScrollLocks();
        })

        const teamCards = document.querySelectorAll('.team-card');

        teamCards.forEach(card => {
            const link = card.querySelector('.link');
            const image = card.querySelector('.team-card__img img');
            const title = card.querySelector('.team-card__name').textContent;
            const subtitle = card.querySelector('.team-card__text').textContent;
            const desc = card.querySelector('.team-card__desc').innerHTML;
            if (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    if (image) {
                        popupImage.setAttribute('src', image.getAttribute('src'));
                    }
                    popupTitle.textContent = title;
                    popupSubtitle.textContent = subtitle;
                    popupDesc.innerHTML = desc;
                    teamPopup.classList.add('open');
                    bodyScrollLock.disableBodyScroll(teamPopup);
                })
            }
        })
    }
})()