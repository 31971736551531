import getCheckedValuesAndFilterAll from "./utils/getCheckedValuesAndFilterAll.js";

(function () {
  $(document).on("click", '.ajax-pagination', function (e) {
    e.preventDefault();

    let container = $(".ajax-pagination-container"),
        form      = $(".sidebar-filters"),
        searchBox = document.querySelector(".directories-search .search-box") || document.querySelector(".search-results .search-box"),
        data      = {
          action   : "paginate_filter_posts",
          page     : $(this).data("page"),
          post_type: container.data("post-type") || "",
          order    : form.find("input[name='order']:checked").val(),
          types    : getCheckedValuesAndFilterAll(form, "type"),
          years    : getCheckedValuesAndFilterAll(form, "publish_year"),
        };

    if (searchBox && searchBox.value != '') {
      data.search = searchBox.value;
    }

    $.ajax({
      url: _wpObject.ajaxUrl,
      dataType: "json",
      type: "POST",
      data: data,
      success: function (response) {
        if (response.success) {
          container.html(response.data);
          // calculateNewsHeight();
        }
      },
      error: function (error) {
        console.log(error);
      },
    });
  });

  // calculateNewsHeight();

  // function calculateNewsHeight() {
  //     const newsCards = document.querySelectorAll('.news-card ');
  //     if (newsCards) {
  //         newsCards.forEach(card => {
  //             if (window.innerWidth > 768) {
  //                 const titleHeight = card.querySelector('.news-card__date').clientHeight + card.querySelector('.news-card__title').clientHeight;
  //                 const content = card.querySelector('.news-card__content');
  //                 content.style.transform = `translateY(calc(100% - ${titleHeight}px))`
  //             }
  //         })
  //     }
  // }
})();
