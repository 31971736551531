(function () {
  const loopEl = document.querySelector('.lit-loop');
  if (loopEl) {
    const loopSteps = loopEl.querySelectorAll('.lit-loop__steps li');
    const infos = loopEl.querySelectorAll('.lit-loop__info')
    loopSteps.forEach((step, index) => {
      step.addEventListener('click', function () {
        loopSteps.forEach((insideStep, i) => {
          if (i !== index) {
            insideStep.classList.remove('active');
          } else {
            insideStep.classList.add('active')
          }
        })
        infos.forEach((info, i) => {
          if (i !== index) {
            info.classList.remove('active');
          } else {
            info.classList.add('active')
          }
        })
      })
    })
  }
})()