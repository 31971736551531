document.addEventListener("DOMContentLoaded", function() {  // Don't touch
  // Don't touch
  objectFitImages();
  svg4everybody();
  // End don't touch

  require('./target-blank.js');
  require('./sliders');
  require('./menu');
  require('./map');
  require('./video-list');
  require('./video');
  require('./custom-paginate');
  require('./posts-filter.js');
  require('./regional-map');
  require('./team')
  require('./tax-grid')
  require('./newsroom');
  require('./tabs');
  require('./events');
  require('./loop')
});

// remove preloader
window.addEventListener('load', function () {
  document.body.classList.remove('js')
  document.querySelector('html').classList.add('page-loaded');
  AOS.init({
    once: true,
    useClassNames: true,
    duration: 500,
    delay: 200,
    // easing: 'linear',
    // easing: 'ease',
    // easing: 'ease-in',
    // easing: 'ease-out',
    // easing: 'ease-in-out',
    // easing: 'ease-in-back',
    // easing: 'ease-out-back',
    // easing: 'ease-in-out-back',
    // easing: 'ease-in-sine',
    // easing: 'ease-out-sine',
    // easing: 'ease-in-out-sine',
    // easing: 'ease-in-quad',
    // easing: 'ease-out-quad',
    // easing: 'ease-in-out-quad',
    // easing: 'ease-in-cubic',
    // easing: 'ease-out-cubic',
    // easing: 'ease-in-out-cubic',
    // easing: 'ease-in-quart',
    // easing: 'ease-out-quart',
    easing: 'ease-in-out-quart',

  });

  adjustIframeHeight()

  window.addEventListener('message', function (event) {
    if (event.origin !== window.location.origin) {
      return; // Verify the origin of the message
    }

    if (event.data && event.data.type === 'iframeHeight') {
      document.getElementById('myIframe').style.height = event.data.height + 'px';
    }
  });

  const aboutIntro = document.querySelector('.about-intro');
  if (aboutIntro) {
    const aboutIntroCTA = aboutIntro.querySelector('.cta-wrapper');
    const aboutIntroContent = aboutIntro.querySelector('.about-intro__content');
    const aboutIntroTitle = aboutIntro.querySelector('.about-intro__title');
    if (window.innerWidth < 768) {
      if (aboutIntroCTA && !aboutIntroContent.querySelector('.cta')) {
        aboutIntroContent.append(aboutIntroCTA)
      }
    }
    window.addEventListener('resize', function () {
      adjustIframeHeight()
      if (window.innerWidth > 1024) {
        equalizeHeight('.team-preview .cite-with-photo')
        equalizeHeight('.service-card__title')
        equalizeHeight('.contact-card__logo')
      }
      if (window.innerWidth < 768) {
        if (aboutIntroCTA && !aboutIntroContent.querySelector('.cta')) {
          aboutIntroContent.append(aboutIntroCTA)
        }
      } else {
        if (aboutIntroCTA && !aboutIntroTitle.querySelector('.cta')) {
          aboutIntroTitle.append(aboutIntroCTA);
        }
      }
    })
  }

  const featuredSlides = document.querySelectorAll('.featured-slide');
  if (featuredSlides) {
    featuredSlides.forEach(feature => {
      const titleHeight = feature.querySelector('.featured-slide__title').clientHeight;
      const content = feature.querySelector('.featured-slide__content');
      content.style.transform = `translateY(calc(100% - ${titleHeight}px))`
    })
  }


  if (window.innerWidth > 1024) {
    equalizeHeight('.team-preview .cite-with-photo')
    equalizeHeight('.service-card__title')
    equalizeHeight('.contact-card__logo')
  }

  // const gSelect = document.querySelectorAll('.gfield--type-address select');
  // if (gSelect) {
  //   gSelect.forEach(select => select.classList.add('custom-select'));
  // }

  new NativejsSelect({
    selector: '.custom-select'
  });

  // const container = document.querySelector('.tab-container')
  // tabs(container);

  const copyTextEl = document.querySelectorAll('.copy');
  copyTextEl.forEach(el => {
    el.addEventListener('click', function (e) {
      e.preventDefault();
      navigator.clipboard.writeText(el.getAttribute('href'));
    })
  })

});

function equalizeHeight (className) {
  const collection = document.querySelectorAll(className);
  let titleMaxHeight = 0
  collection.forEach(el => {
        titleMaxHeight = Math.max(titleMaxHeight, el.offsetHeight);
      }
  )
  collection.forEach(el => el.style.minHeight = titleMaxHeight + 'px')
}


function adjustIframeHeight() {
  var iframe = document.getElementById('myIframe');

  if (iframe?.contentDocument && iframe?.contentDocument.body) {
    var height = iframe.contentDocument.body.scrollHeight;
    iframe.contentWindow.postMessage({
      type: 'adjustHeight',
      height: height,
    }, '*');
  }
}