(function () {
    const map = document.getElementById('map');
    if (map) {
        const areas = map.querySelectorAll('.map-area');
        const groups = map.querySelectorAll('g');
        const link = document.getElementById('map-authority-link');
        const authority = document.querySelector('.map__bottom');
        const labels = document.querySelectorAll('.map-with-titles__label');
        areas.forEach(area => {
            area.addEventListener('click', function () {
                if (!authority.classList.contains('clicked')) {
                    authority.classList.add('clicked');
                }
                const secondLink = document.getElementById('map-authority-link-2');
                if (secondLink) {
                    secondLink.remove()
                }
                // groups.forEach(group => group.classList.remove('active'));
                areas.forEach(item => item.classList.remove('active'));
                const id = this.getAttribute('id');
                this.classList.add('active');
                showLabel(id)
                // this.parentNode.classList.add('active');
                const linkText = this.getAttribute('data-authority');
                const url = this.getAttribute('data-authority-url');
                link.textContent = linkText;
                link.setAttribute('href', url);
                const secondLinkText = this.getAttribute('data-authority-2');
                if (secondLinkText) {
                    const url2 = this.getAttribute('data-authority-2-url');
                    const rda = document.createElement('a');
                    rda.textContent = secondLinkText;
                    rda.setAttribute('href', url2)
                    rda.setAttribute('id', 'map-authority-link-2');
                    document.querySelector('.map__bottom-layout div').append(rda)
                }
            })

            area.addEventListener('mouseenter', function () {
                const id = this.getAttribute('id');
                const label = document.querySelector(`.map-with-titles__label--${id}`);
                label.classList.add('hover')
            })
            area.addEventListener('mouseleave', function () {
                const id = this.getAttribute('id');
                const label = document.querySelector(`.map-with-titles__label--${id}`);
                label.classList.remove('hover')
            })
        })

        function showLabel(id) {
            if (labels) {
                labels.forEach(label => {
                    if (label.classList.contains(`map-with-titles__label--${id}`)) {
                        label.classList.add('active')
                    } else {
                        label.classList.remove('active');
                    }
                })
            }
        }
    }
})()