(function () {
    const newsFilters = document.querySelector('.sidebar-filters');
    const articleSidebar = document.querySelector('.article__sidebar');

    if (newsFilters) {
        const filterHeader = newsFilters.querySelector('.sidebar-filters__hero')
        filterHeader.addEventListener('click', function () {
            if (window.innerWidth < 768) {
                newsFilters.classList.toggle('opened');
            }
        })
        if (window.innerWidth >= 768) {
            newsFilters.classList.add('opened');
        }
        window.addEventListener('resize', function () {
            if (window.innerWidth >= 768) {
                newsFilters.classList.add('opened');
            } else {
                newsFilters.classList.remove('opened');
            }
        })
    }

    if (articleSidebar) {
        const articleSidebarHeader = articleSidebar.querySelector('.article__sidebar-header');
        articleSidebarHeader.addEventListener('click', function () {
            if (window.innerWidth < 768) {
                articleSidebar.classList.toggle('opened');
            }
        })

        if (window.innerWidth >= 768) {
            articleSidebar.classList.add('opened');
        }
        window.addEventListener('resize', function () {
            if (window.innerWidth >= 768) {
                articleSidebar.classList.add('opened')
            } else {
                articleSidebar.classList.remove('opened')
            }
        })
    }

    const filterForm = document.querySelector('.sidebar-filters');
    if (filterForm) {
        const checkboxBlock = filterForm.querySelectorAll('.type-inputs');

        if (checkboxBlock.length > 0) {
            checkboxBlock.forEach(block => {
                const checkboxes = block.querySelectorAll('.custom-checkbox input');

                checkboxes.forEach(checkbox => {
                    //TODO: @Yegor - refactor this and select "All" checkbox if other is unchecked
                    checkbox.addEventListener('change', function () {
                        if (this.value.toLowerCase() === 'all') {
                            if (this.checked) {
                                checkboxes.forEach(item => {
                                    if (item.value.toLowerCase() === 'all') return;

                                    item.checked = false;
                                })
                            }
                        } else {
                            if (this.checked) {
                                checkboxes.forEach(item => {
                                    if (item.value.toLowerCase() === 'all') item.checked = false;
                                })
                            }
                        }
                    })
                })
            })
        }
    }
})()