(function () {

    let prevArrow = `
    <button type="button" class="slick-arrow__prev">
            <svg width="14" height="28" viewBox="0 0 14 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 28V0L14 14L0 28Z" fill="#0C436A"/>
        </svg>
      <span>prev</span>
    </button>
  `;

    let nextArrow = `
    <button type="button" class="slick-arrow__next">
               <svg width="14" height="28" viewBox="0 0 14 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 28V0L14 14L0 28Z" fill="#0C436A"/>
        </svg>
      <span>prev</span>
    </button>
  `;

    $('.featured-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow,
        nextArrow,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    variableWidth: true,
                    slidesToShow: 1
                }
            },
        ]
    });

    $('.press-releases-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow,
        nextArrow,
        responsive: [
            {
                breakpoint: 1365,
                settings: {
                    arrows: false,
                    variableWidth: true,
                    slidesToShow: 1
                }
            },
        ]
    });

    $('.media-slider').slick({
        variableWidth: true,
        slidesToShow: 1,
        prevArrow,
        nextArrow,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                }
            },
        ]
    });

    $('.article-slider').slick({
        mobileFirst: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    prevArrow,
                    nextArrow,
                    variableWidth: false,
                }
            },
        ]
    });

    $('.grid--mobile-slider')
        .on('destroy', function (ev, slick) {
            let carousel = $(this),
                reinit = debounce(function () {
                    carousel.slick(slick.options);
                    window.removeEventListener('resize', reinit);
                }, 500);

            // Assign our debounced callback to window.resize.
            window.addEventListener('resize', reinit);
        })
        .slick({
            mobileFirst: true,
            slidesToShow: 1,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 768,
                    settings: 'unslick'
                }
            ]
        });

    $('.logo-slider').slick({
        centerMode: true,
        centerPadding: '24px',
        slidesToShow: 7,
        variableWidth: true,
        autoplay: true
    })

    $('.cite-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow,
        nextArrow,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    variableWidth: true,
                    slidesToShow: 1
                }
            },
        ]
    });

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

})()