(function () {

  new NativejsSelect({
    selector: '.custom-select'
  });

  $('#date-from').daterangepicker({
    autoUpdateInput: false,
    locale: {
      format: 'MMM D, YYYY'
    }
  });

  $('#date-to').on('click', function () {
    $('#date-from').trigger('click')
  });

  $('#date-from').on('hide.daterangepicker', function(ev, picker) {
    $('#date-from').val(picker.startDate.format('MMM D, YYYY'));
    $('#date-to').val(picker.endDate.format('MMM D, YYYY'));
  });


  const calendar = document.querySelector('#events-calendar');
  let mouseDown = false;
  let startX, scrollLeft;

  if (calendar) {
    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - calendar.offsetLeft;
      scrollLeft = calendar.scrollLeft;
    };

    let stopDragging = function (event) {
      mouseDown = false;
    };
    
    calendar.addEventListener('mousemove', (e) => {
      e.preventDefault();
      if (!mouseDown) return;
      const x = e.pageX - calendar.offsetLeft;
      const scroll = x - startX;
      calendar.scrollLeft = scrollLeft - scroll;
    });

    calendar.addEventListener('mousedown', startDragging, false);
    calendar.addEventListener('mouseup', stopDragging, false);
    calendar.addEventListener('mouseleave', stopDragging, false);
  }


  const events = document.querySelector('.events-archive');
  if (events) {
    const switchers = document.querySelectorAll('input[name="events-view"]');

    switchers.forEach(switcher => {
      switcher.addEventListener('click', (e) => {
        const value = e.target.value;
        switch (value) {
          case 'list':
            events.classList.remove('calendar');
            events.classList.add('list');
            break;
          case 'calendar':
            events.classList.remove('list');
            events.classList.add('calendar');
            break;
          default: break;
        }
      })
    })
  }
})()