
(function () {
    const heroVideo = document.getElementById('hero-video');
    if (heroVideo) {
        const $playButton = document.getElementById('hero-play');
        const poster = document.getElementById('youtube-video-poster');
        const videoType = heroVideo.getAttribute('data-type');
        let heroPlayer;

        if (videoType === 'youtube') {
            const iframe = document.getElementById('youtube-video')
            const videoId = iframe.getAttribute('video-id');
            loadPlayer();
            window.onYouTubeIframeAPIReady = function () {
                initHeroYoutubePlayer(videoId);
            };
        }

        if (videoType === 'static') {
            const video = heroVideo.getElementsByTagName('video')[0];
            if ($playButton) {
                $playButton.addEventListener('click', function () {
                    this.classList.toggle("playing");
                    if (video.paused) {
                        video.play();
                        $playButton.classList.add("playing");
                    } else {
                        video.pause();
                        $playButton.classList.remove("playing");
                    }
                });
                $playButton.dispatchEvent(new Event('click'))
            }
        }

        function onPlayerStateChange(event) {
            let player = event.target;
            let state = player.getPlayerState();
            if (state === 1) {
                poster.style.display = 'none';
            }
        }

        function onPlayerReady(event) {
            let player = event.target;
            player.mute();
            player.playVideo();
            $playButton.classList.add('playing');
            $playButton.addEventListener('click', function () {
                let state = player.getPlayerState();
                this.classList.toggle('playing');
                if (state === 1) {
                    player.pauseVideo();
                } else {
                    player.playVideo();
                }
            })
        }

        function initHeroYoutubePlayer(videoId) {
            console.log('youtube player init')
            heroPlayer = new YT.Player('youtube-video', {
                height: '810',
                width: '1440',
                videoId,
                playerVars: {
                    'loop': 1,
                    'enablejsapi' : 1,
                    'rel': 0,
                    'origin': window.location.origin,
                    'playlist' : videoId,
                    // 'version': 3
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onPlayerStateChange
                }
            });
        }
    }

    const videoBlocks = document.querySelectorAll('.video-block');
    if (videoBlocks) {
        loadPlayer()
        videoBlocks.forEach(video => {
            const playButton = video.querySelector('.video-block__play');
            if (playButton) {
                const fullButton = video.querySelector('.video-block__full');
                const overlay = video.querySelector('.video-block__overlay');
                const videoLink = playButton.getAttribute('data-src');
                const blockId = video.querySelector('.video-block__youtube').getAttribute('id');
                const videoId = videoLink.slice(-11);
                overlay.style.background = `url('https://img.youtube.com/vi/${videoId}/maxresdefault.jpg') 50% 50% no-repeat`;
                overlay.style.backgroundSize = 'cover';


                playButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    onYouTubeIframeAPIReady(videoId, blockId, onPlayerReady);
                })
                fullButton.addEventListener('click', function (e) {
                    e.preventDefault();
                    onYouTubeIframeAPIReady(videoId, blockId, onPlayerFullScreen)
                })
            }
        })
    }

    function onPlayerReady(event) {
        let player = event.target;
        player.playVideo();
        let iframe = player.getIframe();
        const overlay = iframe.nextElementSibling;
        overlay.classList.add('hidden');
    }

    function onPlayerFullScreen(event) {
        let player = event.target;
        player.playVideo();
        let iframe = player.getIframe();
        const overlay = iframe.nextElementSibling;
        overlay.classList.add('hidden');
        var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
        }
    }

    function onYouTubeIframeAPIReady(videoId, blockId, onPlayerReady) {
        let player = new YT.Player(blockId, {
            height: '360',
            width: '640',
            videoId: videoId,
            events: {
                'onReady': onPlayerReady
            }
        });
    }

    function loadPlayer() {
        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            let tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            let firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }

})()