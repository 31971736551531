function getCheckedValuesAndFilterAll(form, name) {
  var values = form
    .find(`input[name='${name}']:checked`)
    .map(function () {
      return $(this).val();
    })
    .get();

  // Remove 'All' from the array if other options are selected
  if (values.length > 1) {
    values = values.filter(function (value) {
      return value !== "All";
    });
  }

  return values;
}

export default getCheckedValuesAndFilterAll;