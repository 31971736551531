import getCheckedValuesAndFilterAll from "./utils/getCheckedValuesAndFilterAll.js";

(function () {
  var form = $(".sidebar-filters");
  var container = $(".ajax-pagination-container");
  var searchBox = document.querySelector(".directories-search .search-box");


  function handleAjaxRequest() {
    let postType = container.data("post-type") || "post",
        order    = form.find("input[name='order']:checked").val(),
        types    = getCheckedValuesAndFilterAll(form, "type"),
        years    = getCheckedValuesAndFilterAll(form, "publish_year");
    
    let data = {
      action: "paginate_filter_posts",
      page: 1, // Reset to the first page
      post_type: postType,
      order: order,
      types: types,
      years: years,
    };

    if (searchBox && searchBox.value != '') {
      data.search = searchBox.value;
    }

    $.ajax({
      url: _wpObject.ajaxUrl,
      dataType: "json",
      type: "POST",
      data: data,
      success: function (response) {
          container.html(response.data);
          // if (postType === 'newsroom') calculateNewsHeight();
      },
      error: function (error) {
        console.error("Filter AJAX request failed: ", error);
      },
    });
  }
  
  // Update the URL with the current search params
  let updateSearchUrl = (form) => {
        
    if ( form === undefined ) return null;

    let searchParams  = new URLSearchParams(window.location.search),
        checked = form.querySelectorAll('.custom-checkbox input:checked'),
        order   = form.querySelectorAll('.custom-radio input:checked'),
        params  = {};
    
    if (order[0] && order[0].value === 'asc') {
      params.order = 'asc';
    }

    checked.forEach(input => {
      // if (input.value == 'all') return;

      params[input.name] = params[input.name] || [];

      params[input.name].push(input.value.toLowerCase());
    });

    Object.keys(params).forEach(key => {
      if ( searchParams.has(key) ) {
        searchParams.delete(key);
      }

      if ( params[key].indexOf('all') === 0 ) return;

      if ( order[0] && order[0].value === 'desc' && searchParams.has('order') ) {
        searchParams.delete('order');
      }

      searchParams.set(key, params[key].toString());
    });

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${searchParams.toString()}`
    );
  }

  // Handle change event
  form.on("change", function (e) {
    updateSearchUrl(this);

    handleAjaxRequest();
  });

  // Handle reset event
  form.on("reset", function (e) {
    // Wait for the form to reset
    setTimeout(function () {
      handleAjaxRequest();
    }, 100);
  });

  var timeoutId = null;
  if (searchBox) {
    searchBox.addEventListener("input", function (e) {
      // Clear the existing timeout on each input event
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout
      timeoutId = setTimeout(function () {
        // console.log("User stopped typing");
        handleAjaxRequest();
      }, 500); // Wait 500ms before firing the AJAX request
    });
    var searchButton = searchBox.nextElementSibling;
    searchButton.addEventListener("click", function (e) {
      e.preventDefault();
      handleAjaxRequest();
    });
  }

  // function calculateNewsHeight() {
  //   const newsCards = document.querySelectorAll('.news-card ');
  //   if (newsCards) {
  //       newsCards.forEach(card => {
  //         if (window.innerWidth > 768) {
  //           const titleHeight = card.querySelector('.news-card__date').clientHeight + card.querySelector('.news-card__title').clientHeight;
  //           const content = card.querySelector('.news-card__content');
  //           content.style.transform = `translateY(calc(100% - ${titleHeight}px))`
  //         }
  //       })
  //   }
  // }

})();
