(function () {
  const tabsContainer = document.querySelector('.tab-container');

  if (tabsContainer) {
    const tabs = tabsContainer.querySelectorAll('.tab');
    const panes = tabsContainer.querySelectorAll('.tab-pane');

    tabs.forEach((tab, i) => {
      tab.addEventListener('click', function (e) {
        activate(tabs, i);
        activate(panes, i);
      });
    })
  }

  function activate(tabs, index) {
    tabs.forEach((tab, i) => {
      if (i != index) {
        removeClass(tab, 'active')
      } else {
        addClass(tab, 'active')
      }
    })
  }

  function hasClass(el, cls) {
    return el.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
  }

  function addClass(el, cls) {
    if (!hasClass(el, cls)) {
      el.className += " " + cls;
      const slider = $('.tabs-slider');
      console.log(slider)
      if (slider) {
        slider.slick('refresh')
      }
      const featuredSlides = el.querySelectorAll('.featured-slide');
      if (featuredSlides) {
        featuredSlides.forEach(feature => {
          const titleHeight = feature.querySelector('.featured-slide__title').clientHeight;
          const content = feature.querySelector('.featured-slide__content');
          content.style.transform = `translateY(calc(100% - ${titleHeight}px))`
        })
      }
    }
  }

  function removeClass(el, cls) {
    if (hasClass(el, cls)) {
      let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
      el.className = el.className.replace(reg, '');
    }
  }
})()