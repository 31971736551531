(function () {

    const $header = document.querySelector('.header');
    const $menu = document.querySelector('.main-nav');
    const $hamburger = document.querySelector('.burger');
    const $firstLevelLinks = document.querySelectorAll('.main-nav > .menu > .menu-item-has-children > a');
    const $secondLevelLinks = document.querySelectorAll('.main-nav .menu .sub-menu > .menu-item-has-children > a');
    const $backLink = $header.querySelector('.back-link');
    const $footer = document.querySelector('.footer');
    const $footerContainer = $footer.querySelector('.container');
    const $footerCenter = $footer.querySelector('.footer__center');
    const $footerLinks = $footer.querySelectorAll('.footer__nav .menu-item-has-children');
    const $searchOpener = document.querySelector('.header__search-opener');
    const $search = document.querySelector('.header__search');
    const $searchClose = $search.querySelector('.header-search__close');

    let isMenuOpened = false;

    const $sitemap = $footer.querySelector('.footer__sitemap');
    const $sitemapOpener = $footer.querySelector('.sitemap-opener');

    if ($sitemapOpener) {
        $sitemapOpener.addEventListener('click', function (e) {
            e.preventDefault();
            $sitemap.classList.toggle('opened');
            this.classList.toggle('opened');
            setTimeout(function () {
                window.scrollTo({
                    left: 0,
                    top: document.body.scrollHeight + $sitemap.querySelector('.footer__nav').clientHeight + 100,
                    behavior: 'smooth'
                })
            }, 200)
        })
    }

    if ($searchOpener) {
        $searchOpener.addEventListener('click', function (e) {
            e.preventDefault();
            this.classList.add('search-opened');
            $search.classList.add('opened');
            bodyScrollLock.disableBodyScroll($search);
            $hamburger.classList.remove('opened')
            $menu.classList.remove('opened');
            $header.classList.remove('menu-opened');
            isMenuOpened = !isMenuOpened;
        })

        $searchClose.addEventListener('click', function (e) {
            e.preventDefault();
            $searchOpener.classList.remove('search-opened');
            $search.classList.remove('opened');
            bodyScrollLock.clearAllBodyScrollLocks();
        })
    }

    $hamburger.addEventListener('click', function () {
        this.classList.toggle('opened')
        $menu.classList.toggle('opened');
        $header.classList.toggle('menu-opened');
        isMenuOpened = !isMenuOpened;
        $search.classList.remove('opened');

        if (isMenuOpened) {
            bodyScrollLock.disableBodyScroll($menu);
        } else {
            bodyScrollLock.clearAllBodyScrollLocks();
            $menu.classList.remove('second-menu-opened');
            $menu.classList.remove('third-menu-opened');
            $firstLevelLinks.forEach(item => {
                item.parentNode.classList.remove('children-menu-opened');
            })
            $secondLevelLinks.forEach(item => {
                item.parentNode.classList.remove('children-menu-opened');
            })
            $backLink.textContent = '';
            $backLink.classList.add('hidden');
        }
    });

    if (window.innerWidth < 1024) {
        if (!document.querySelector('.footer__center > .footer__sitemap')) {
            $footerCenter.append($sitemap);
        }
    }

    window.addEventListener('resize', debounce(function () {
        if (isMenuOpened && window.innerWidth >= 768) {
            bodyScrollLock.clearAllBodyScrollLocks();
            $header.classList.remove('second-menu-opened');
            $firstLevelLinks.forEach(item => {
                item.classList.remove('children-menu-opened');
            })
        }
        if (window.innerWidth >= 1024) {
            if (!document.querySelector('.footer > .container > .footer__sitemap')) {
                $footerContainer.append($sitemap);
            }
        } else {
            if (!document.querySelector('.footer__center > .footer__sitemap')) {
                $footerCenter.append($sitemap);
            }
        }
    }))

    $firstLevelLinks.forEach(item => {
        item.addEventListener('click', function (e) {
            if (isMenuOpened) {
                e.preventDefault();
                const link = this.cloneNode(true);
                createBackLink(link, 2)
                $backLink.classList.remove('hidden');
                $menu.classList.add('second-menu-opened');
                this.parentNode.classList.add('children-menu-opened');
            }
        })
    })

    $secondLevelLinks.forEach(item => {
        item.addEventListener('click', function (e) {
            if (isMenuOpened) {
                e.preventDefault();
                const link = this.cloneNode(true);
                createBackLink(link, 3)
                $menu.classList.add('third-menu-opened');
                this.parentNode.classList.add('children-menu-opened');
            }
        })
    })

    function createBackLink(link, level) {
        link.classList.add(`level-${level}`);
        $backLink.appendChild(link);
    }

    $backLink.addEventListener('click', function () {
        if ($menu.classList.contains('third-menu-opened')) {
            $menu.classList.remove('third-menu-opened');
            $menu.classList.add('second-menu-opened');
            const link3 = $backLink.querySelector('.level-3');
            link3.remove();
            $secondLevelLinks.forEach(item => {
                item.parentNode.classList.remove('children-menu-opened');
            })
        } else {
            $menu.classList.remove('second-menu-opened');
            const link2 = $backLink.querySelector('.level-2');
            link2.remove();
            $backLink.classList.add('hidden');
            $firstLevelLinks.forEach(item => {
                item.parentNode.classList.remove('children-menu-opened');
            })
        }
    })

    $footerLinks.forEach(link => {
        link.addEventListener('click', function (e) {
            if (window.innerWidth < 1024) {
                e.preventDefault();
                link.querySelector('.sub-menu').classList.toggle('opened');
                link.classList.toggle('opened');
            }
        })
    })

    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
})();
