(function () {

    let player;

    const videoListBlock = document.querySelector('.video-list-block');
    const videoListPlayerId = 'video-list-player';
    if (videoListBlock) {
        const videoList = videoListBlock.querySelectorAll('.video-list-item');
        const videoListPlayer =  document.getElementById(videoListPlayerId)
        const playButton = videoListPlayer.nextElementSibling.querySelector('.video__play')
        const overlay = videoListPlayer.nextElementSibling;
        const videoLink = playButton.getAttribute('data-src');
        const videoId = videoLink.slice(-11);
        overlay.style.background = `url('https://img.youtube.com/vi/${videoId}/hqdefault.jpg') 50% 50% no-repeat`;
        overlay.style.backgroundSize = 'cover';
        loadPlayer(videoId);
        playButton.addEventListener('click', function (e) {
            e.preventDefault();
            overlay.classList.add('hidden');
            player.playVideo();
        })
        videoList.forEach(video => {
            video.addEventListener('click', function () {
                const videoElLink = video.getAttribute('data-src');
                const videoElId = videoElLink.slice(-11);
                player.pauseVideo();
                player.loadVideoById({'videoId': videoElId});
                player.playVideo();
                overlay.classList.add('hidden');
            })
        })

    }

    function onYouTubeIframeAPIReady(videoId) {
        player = new YT.Player(videoListPlayerId, {
            height: '360',
            width: '640',
            videoId: videoId,
        });
    }

    function loadPlayer(videoId) {
        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

            if (videoListPlayerId) {
                window.onYouTubePlayerAPIReady = function () {
                    onYouTubeIframeAPIReady(videoId);
                };
            }
        }
    }
})()